let dark = {
  primary: "#0087FF",
  secondary: "#424242",
  accent: "#82B1FF",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107",
};

let light = {
  primary: "#0087FF",
  secondary: "#4CBBB9",
  // accent: "#EFF3C6",
  accent: "#005082",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107",
};

export { light, dark };
