export default {
  setToken(value) {
    window.localStorage.setItem("ipltoken", value);
  },

  getToken() {
    let token = window.localStorage.getItem("ipltoken");
    if (!token) {
      return null;
    } else {
      return token;
    }
  },

  destroyToken() {
    window.localStorage.removeItem("ipltoken");
  },

  isAuthenticated() {
    if (this.getToken()) {
      return true;
    }
    return false;
  },
};
