import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "@/packages/auth";
const Login = () =>
  import(/* webpackChunkName: 'Login' */ "../views/Login.vue");
const Dashboard = () =>
  import(/* webpackChunkName: 'Dashboard' */ "../views/Dashboard.vue");
const Home = () => import(/* webpackChunkName: 'Home' */ "../views/Home.vue");
const Leaderboard = () =>
  import(/* webpackChunkName: 'Leaderboard' */ "../views/Leaderboard.vue");
const Profile = () =>
  import(/* webpackChunkName: 'Profile' */ "../views/Profile.vue");
const Results = () =>
  import(/* webpackChunkName: 'Results' */ "../views/Results.vue");
const Prediction = () =>
  import(/* webpackChunkName: 'Prediction' */ "../views/Prediction.vue");
const Rules = () =>
  import(/* webpackChunkName: 'Rules' */ "../views/Rules.vue");
const ManualPrediction = () =>
  import(
    /* webpackChunkName: 'ManualPrediction' */ "../views/ManualPrediction.vue"
  );
const Matches = () =>
  import(/* webpackChunkName: 'Matches' */ "../views/Matches.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: { requiresAuth: false },
    beforeEnter: (to, from, next) => {
      if (Auth.isAuthenticated()) {
        next({
          name: "dashboard.home",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "dashboard.home",
        meta: { requiresAuth: true },
        component: Home,
      },
      {
        path: "leaderboard",
        name: "dashboard.leaderboard",
        meta: { requiresAuth: true },
        component: Leaderboard,
      },
      {
        path: "profile",
        name: "dashboard.profile",
        meta: { requiresAuth: true },
        component: Profile,
      },
      {
        path: "results",
        name: "dashboard.results",
        meta: { requiresAuth: true },
        component: Results,
      },
      {
        path: "prediction",
        name: "dashboard.prediction",
        meta: { requiresAuth: true },
        component: Prediction,
      },
      {
        path: "manual-pred",
        name: "dashboard.manual-pred",
        meta: { requiresAuth: true },
        component: ManualPrediction,
      },
      {
        path: "rules",
        name: "dashboard.rules",
        meta: { requiresAuth: true },
        component: Rules,
      },
      {
        path: "matches",
        name: "dashboard.matches",
        meta: { requiresAuth: true },
        component: Matches,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Auth.isAuthenticated()) {
      next({
        name: "login",
      });
    } else {
      next(); // make sure to always call next()!
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
