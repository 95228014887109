import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    storeCurrentPlayer(context, currentPlayer) {
      context.commit("setCurrentPlayer", currentPlayer);
    },
    storeTeams(context, teams) {
      context.commit("setTeams", teams);
    },
    storeMatches(context, matches) {
      context.commit("setMatches", matches);
    },
    storePlayers(context, players) {
      context.commit("setPlayers", players);
    },
    storeLeaderboard(context, leaderboard) {
      context.commit("setLeaderboard", leaderboard);
    },
    storePredictions(context, prediction) {
      context.commit("setPrediction", prediction);
    },
  },
  state: {
    currentPlayer: {},
    teams: [],
    matches: [],
    players: [],
    leaderboard: [],
    prediction: [],
  },
  getters: {
    currentPlayer: (state) => state.currentPlayer,
    teams: (state) => state.teams,
    matches: (state) => state.matches,
    players: (state) => state.players,
    leaderboard: (state) => state.leaderboard,
    prediction: (state) => state.prediction,
  },
  mutations: {
    setCurrentPlayer(state, currentPlayer) {
      state.currentPlayer = state.players.find(
        (pl) => pl.email == currentPlayer.email
      );
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setMatches(state, matches) {
      state.matches = matches.map((m) => {
        return {
          ...m,
          newDate: new Date(m.date.seconds * 1000),
          team1Full: state.teams.find((t) => m.team1 == t.acronym),
          team2Full: state.teams.find((t) => m.team2 == t.acronym),
        };
      });
    },
    setPlayers(state, players) {
      state.players = players;
    },
    setLeaderboard(state, leaderboard) {
      state.leaderboard = leaderboard.map((lb, index) => {
        return {
          position: index + 1,
          ...lb,
        };
      });
    },
    setPrediction(state, prediction) {
      state.prediction = prediction;
    },
  },
  modules: {},
});
