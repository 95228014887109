import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import { light, dark } from "./themes";

Vue.use(Vuetify);

const opts = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light,
      dark,
    },
  },
  icons: {
    iconfont: "fa",
  },
};

export default new Vuetify(opts);
