import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";

Vue.config.productionTip = false;

// // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBHc-4HCEbzkovlVgSTlUfvemHhSwxPBek",
  authDomain: "tcipl-417920.firebaseapp.com",
  projectId: "tcipl-417920",
  storageBucket: "tcipl-417920.appspot.com",
  messagingSenderId: "698098603850",
  appId: "1:698098603850:web:71624519eea4426a7ed3b3",
};

// // Initialize Firebase
Vue.prototype.$firebase = initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
